
import Base from '@/api-base/base'
const BaseURL = `Referencias`;
import Constantes from '@/api-services/Constantes'
import Personas from '@/api-base/personas.service'

export default {
  //Obtiene la ruta del front de la referencia con el LinkID
  ObtenUrlLinkID(LinkID) {

    return `${Constantes.URL}r/${LinkID}?${Personas.PersonaIDParam}`;
  },
  //Regresa undefined si no hay alguna referencia leida
  getReferencia() {
    var Ls = localStorage.referencia;

    if (Ls != null && Ls != "")
      return JSON.parse(Ls);
    return Ls;
  },
  //Asigna la ultima referencia leida
  setReferencia(Referencia) {
    localStorage.referencia = JSON.stringify( Referencia);
  },
  GetsResponse: null,
  Listado: [],
  FotoURL(Registro) {
    return "/img/grupos.svg";
  },
  gets(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {

    return Base.getsBaseSuscripcion(Vista, BaseURL, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado);
  },
  getsSelect(Vista, OnCorrecto, OnErroneo, OnFinalizado) {
    if (this.GetsResponse != null)
      OnCorrecto(this.GetsResponse);
    return Base.getsBaseSuscripcion(Vista, BaseURL, { PaginaLen: 1000 }, (response) => {
      this.GetsResponse = response;
      this.Listado = response.data.listado;
      if (OnCorrecto != undefined)
        OnCorrecto(response);

    }, OnErroneo, OnFinalizado);
  },
  put(Vista, ID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}`);
    return Base.put(Vista, URL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  post(Vista, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.postBaseSuscripcion(Vista, BaseURL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  get(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(Vista, `${BaseURL}/${id}`, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  delete(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.deleteBaseSuscripcion(Vista, `${BaseURL}/${id}`, OnCorrecto, OnErroneo, OnFinalizado);
  },

  get(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(Vista, `${BaseURL}/${id}`, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },

  patch(Vista, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.patch(Vista, `${Base.URL}/${BaseURL}`, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  LinkIDAperturaPost(Vista, linkID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.post(Vista, `${Base.URL}/${BaseURL}/LinkID/${linkID}/Apertura`, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },

  FunnelGets(Vista, Parametros, OnCorrecto, OnErroneo, OnFinalizado) {

    return Base.get(Vista, `${Base.URL}/${BaseURL}/Funnel`, { params: Parametros }, OnCorrecto, OnErroneo, OnFinalizado);
  },
}
