
import Base from '@/api-base/base'
const BaseURL = `Personas`;
//import Constantes from '@/api-services/Constantes'


export default {
  get PersonasIDs() {
    var json = localStorage['PersonasIDs'];
    if (json == null || json == "")
      return [];
    return JSON.parse(json);

  },
  set PersonasIDs(valor) {
    if (valor == undefined || valor == null)
      localStorage.PersonasIDs = null;
    else
      localStorage.PersonasIDs = JSON.stringify(valor);
  },
  AgregaPersonaID(PersonaID) {
    var PIDs = this.PersonasIDs;
    if (PIDs == null)
      PIDs = [];
    if (!PIDs.includes(PersonaID)) {
      PIDs.push(PersonaID);
      this.PersonasIDs = PIDs;
    }
  },
  get Persona() {
    var json = localStorage['Persona'];
    if (json == null || json == "")
      return null;
    return JSON.parse(json);

  },
  set Persona(valor) {
    if (valor == undefined || valor == null)
      localStorage.Persona = null;
    else
      localStorage.Persona = JSON.stringify(valor);
  },
  get PersonaIDParam() {
    var R = this.Persona;
    if (R == null || R.id == null)
      return "";
    return `PersonaID=${R.id}`;

  },
  FotoURL(Registro) {
    return "/img/grupos.svg";
  },
 /* gets(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {

    return Base.get(Vista, `${Base.URL}/${BaseURL}`, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado);
  },
  getsSelect(Vista, OnCorrecto, OnErroneo, OnFinalizado) {
    if (this.GetsResponse != null)
      OnCorrecto(this.GetsResponse);
    return Base.getsBaseSuscripcion(Vista, BaseURL, { PaginaLen: 1000 }, (response) => {
      this.GetsResponse = response;
      this.Listado = response.data.listado;
      if (OnCorrecto != undefined)
        OnCorrecto(response);

    }, OnErroneo, OnFinalizado);
  },*/
  put(Vista, ID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {

    return Base.put(Vista, `${Base.URL}/${BaseURL}/${ID}`, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  post(Vista, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.post(Vista, `${Base.URL}/${BaseURL}`, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  get(Vista, ID, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.get(Vista, `${Base.URL}/${BaseURL}/${ID}`, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  NoSoyYo(Vista, ID, Parametros, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = `${Base.URL}/${BaseURL}/${ID}/NoSoyYo`;
    // console.debug(params);
    return Base.get(Vista, URL, { params: Parametros }, OnCorrecto, OnErroneo, OnFinalizado);
  },
  getCampos(Vista, OnCorrecto, OnErroneo, OnFinalizado) {

    return Base.getsBaseSuscripcion(Vista, `${BaseURL}/Campos`, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  delete(Vista, ID, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.delete(Vista, `${Base.URL}/${BaseURL}/${ID}`, OnCorrecto, OnErroneo, OnFinalizado);
  },

  SelfiePatch(Vista, ID, Orden, params, Archivo, OnCorrecto, OnErroneo, OnFinalizado) {
    let formData = new FormData();
    formData.append('Foto', Archivo);
    return Base.patch(Vista, `${Base.URL}/${BaseURL}/${ID}/Selfie/${Orden}`, formData, {
      params,
      headers: {
        'Content-Type': 'multipart/form-data',
      },

    }, OnCorrecto, OnErroneo, OnFinalizado);
  },
  IdentificacionPatchParams(Vista, ID, Orden, params, Archivo, OnCorrecto, OnErroneo, OnFinalizado) {
    let formData = new FormData();
    formData.append('Foto', Archivo);

    return Base.patch(Vista, `${Base.URL}/${BaseURL}/${ID}/Identificacion/${Orden}`,
      formData, {
      params,
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }, OnCorrecto, OnErroneo, OnFinalizado);
  },
  IdentificacionPatch(Vista, ID, Orden, Llave, Archivo, OnCorrecto, OnErroneo, OnFinalizado) {
    let formData = new FormData();
    formData.append('Foto', Archivo);
    return Base.patch(Vista, `${Base.URL}/${BaseURL}/${ID}/Identificacion/${Orden}?Llave=${Llave}`, formData, {

      headers: {
        'Content-Type': 'multipart/form-data',
      },

    }, OnCorrecto, OnErroneo, OnFinalizado);
  },

}
