<template>
  <div class="text-center">
    <hr class="my-4" />
    <!--  <base-button v-if="!cargando" native-type="submit" type="primary" :disabled="!PuedeAgregar">
      Agregar
    </base-button>-->
    <div v-if="cargando">
      <b-spinner type="grow" label="cargando..."></b-spinner>
      Cargando...
    </div>
    <p v-if="error" class="badge badge-danger text-wrap">
      {{ error }}
    </p>
  </div>
</template>

<script>
  import Servicio from '@/api-base/referencias.service';
  import SuscripcionesUsuarios from '@/api-base/suscripciones.usuarios.service';

  export default {
    name: 'Consulta-Referencia',
    props: {
      // linkID
      value: { type: String, default: '' },
    },
    data() {
      return {
        cargando: true,
        error: ''
      }
    },
    components: {

    },
    filters: {


    },
    computed: {

    },
    mounted() {
      var linkID = '';
      if (this.$route.query.linkID != null)
        linkID = this.$route.query.linkID;
      if (this.$route.params.linkID != null)
        linkID = this.$route.params.linkID;
      var Carga = {};
      if (Number.isInteger(SuscripcionesUsuarios.getSuscripcionID()))
        Carga = { suscripcionID: SuscripcionesUsuarios.getSuscripcionID() };

      Servicio.LinkIDAperturaPost(this, linkID,
        Carga, (response) => {
          var Referencia = response.data;
          console.debug(Referencia);
          Servicio.setReferencia(Referencia);
          switch (Referencia.tipoReferencia) {
            case "NuevaSuscripcion":
              this.$router.replace("/usuarios/nuevasuscripcion");
              break;
            case "NuevoDispositivo":
              this.$router.replace("/dispositivos/qr-nuevo-checador");              
              break;
            case "InvitacionEmpleadoSMS":
            case "InvitacionEmpleadoMail":
              this.$router.replace("/usuarios/invitacion-empleado");
              break;
            case "Checada":
              {
               // this.$router.replace("/empleado/acceso-nuevo");
                SuscripcionesUsuarios.setSuscripcionID(Referencia.suscripcionID);
                this.$router.replace(`/reuniones/AutoRegistro?LinkID=${Referencia.linkID}`);
              }
              break;
            case "LoginAM":
              this.$router.replace("/am/nuevo-dispositivo");
              break;
            case "Nom035Stps2018":
              this.$router.replace(`/nom035stps2018/invitacion-empleado/${Referencia.extra}`);
              break;
            default:
              {
                var TipoID = Referencia.id.split(".")[0];
                switch (TipoID) {
                  //Reuniones
                  case "Re":
                    SuscripcionesUsuarios.setSuscripcionID(Referencia.suscripcionID);
                    this.$router.replace(`/reuniones/${Referencia.extra}?LinkID=${Referencia.linkID}`);
                    break;
                }
              }
              break;
          }
          
          //console.debug(response.data);
        }, (MsgError) => {
          this.error = MsgError;
        });
    },
    methods: {
      test(dataUrl, id) {
        console.log(dataUrl, id)
      }

    }
  }</script>

<style scoped>
</style>
